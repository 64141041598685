.navbar{

    background-color: #110451;
    position: sticky;
    overflow: hidden;
    width: 100%;
  
    
   
    
}


.navimage{
    color: white;
    width:3rem;
    height:3rem;
    border-radius:50%;
    margin-right:20px
}
.navicon{
    padding-right:15px;
    color: white;
    height: 6mm;
    padding: 10px;
    justify-content: space-between;
   

}

.navbarbrand{
    padding-right:70%;
    font-size:5mm;
    color:whitesmoke
}
.navbarbrand:hover{
    color:white
}
.mynav{
  display: none;
  justify-content: space-between;
}






@media only screen and (max-width: 400px){

.navimage{
        display: none;
    }
.navbarbrand{
    display: none;


    
}
.navicon{
    display: none;
}
.mynav{
    float: left;
    width: 100%;
    display:flex;
    background-color:  transparent;
    color: white;
    
   
    
}


.mynav a{
    float: left;
    display: flex;
    color: whitesmoke;
    border: none;
    background-color: transparent;
    text-decoration: none;
    font-size: small;
    padding: 16px 19px;

    
}

    
.navbar a{
    display: block;
    
}
.navbar{
    overflow: hidden;
    position: sticky;
}


}

@media only screen and (max-width: 800px){

    .navimage{
            display: none;
        }
    .navbarbrand{
        display: none;
    
    
        
    }
    .navicon{
        display: none;
    }
    .mynav{
        float: left;
        width: 100%;
        display:flex;
        background-color:  transparent;
        color: white;
        
       
        
    }
    
    
    .mynav a{
        float: left;
        display: flex;
        color: whitesmoke;
        border: none;
        background-color: transparent;
        text-decoration: none;
        font-size: small;
        padding: 16px 19px;
    
        
    }
    
        
    .navbar a{
        display: block;
        
    }
    .navbar{
        overflow: hidden;
        position: sticky;
    }
    
    
    }