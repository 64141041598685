.regcard{
    width:30%;
    justify-self:center;
    font-weight:bold;
    background-color:whitesmoke;
    height: 10%;
    
    


}

.my-header{
    
    width:100%;
    font-size:5mm;
    color:white; 
    text-align:center; 
    font-weight:bold;
    border:none;
    margin: auto;
    height:100%
 

}


@media only screen and (max-width: 800px){

    .regcard{
        width:80%;
        justify-self:center;
        font-weight:bold;
        background-color:whitesmoke;
        height: 10%;
        margin-right: 10px;
        
    
    
    }

   
}
