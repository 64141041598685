.my-container{
    text-align:left;
    margin-right:100%;
    right: 100%;
    margin-top:30px;
    margin-left:18px;
   
}
.main-container{
    padding:15px; 
    padding-left:10px;
    background-color:whitesmoke

}
.mycard-pending{
    display: none;
}

.img{
    max-width: 5cm;
}
.card-img{
    max-width: 5rem;   
}

a:link{
    color: azure;
    text-decoration: none;
}

a:visited{
    text-decoration: none;
}

a:active{
    text-decoration: none;
}

a:hover::before{
    text-decoration: none;
}

.nav-body{
    flex-direction: column;
    bottom: 100px;
   
   
}
.card-style{
    padding-left: 15px;
    padding-bottom: 15px;
    


}

.my-card{
    padding-left:10px;
    padding-bottom:5px;
    border:hidden;
    width:15rem;
    height:10rem;
    padding-top:10px;
    margin: 2px;
    border-radius:10px;
    box-shadow: revert;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
    


}
.mini-card{
    padding-left:10px;
    padding-bottom:5px;
    border:hidden;
    width:12rem;
    height:9rem;
    padding-top:10px;
    margin-left: 20px;
    border-radius:10px;
    box-shadow: revert;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)

}
.mini-button{
    width:100%;
    margin-top: 10px;

}

.my-card-withdraw{
    padding-left:10px;
    padding-bottom:5px;
    border:hidden;
    width:14rem;
    height:10rem;
    padding-top:10px;
    border-radius:10px;
    box-shadow: revert;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


}

.my-card:hover{
    border-color: black;
}

.nocardi{
    width: 30rem;
    margin-left: 10px; 
    height:21rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: whitesmoke;
    
}
.nocardii{
    width: 30rem;
    margin-left: 10px; 
    height:21rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: whitesmoke;
    
}

.mysidebar{
    text-decoration: none;
}
.mynavbar{
    text-decoration: none;
   
}
.myinvest{
    width: 37rem;
    margin-right:35px;
    margin-bottom:10px;
    height:23.5rem;
    border:hidden
}

.mobile-card{
    display: none;
}
.my-icon{
    padding-left:85%;
    color:white
}
.dashboard-div{
    flex:1 1 auto;
    display:flex; 
    flex-flow:column; 
    height:100vh; 
    overflow-x:hidden
}

.table-head-font{
    background-color:#110451;
    color:white;
    font-size: medium;
}


@media only screen and (max-width: 700px){

    .my-card{
        border-radius:50px;
        width:20rem;
        padding-left: 10px;
        padding:10px;
        justify-items: space-between;
        margin: 10px;
        background-blend-mode: normal;
       

    }
    .my-card-withdraw{
        display: none;
    }

    .my-icon{
        padding-left:80%;
        color:white
    }
    
}

@media only screen and (max-width: 400px){

    .my-card{
        border-radius:30px;
        width:16.5rem;
        padding:10px;
        justify-items: center;
        margin: 10px;
        background-blend-mode: normal;
       font-size: small;
       display: block;
       height: 9rem;
       margin-right: 100px;
    

   
    }

    .my-icon{
        padding-left:85%;
        color:white
    }

    .navicons{
        display: none;
    }

    .mysidebar{
        display: none;
    }

    .mynavbar{
        background-color: #333;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .nocardi{
        display: none;
    }
    .nocardii{
        display: none;
    }

    .my-card-withdraw{
        display: none;
    }

    .myinvest{
        width: 20rem;
        margin-right:35px;
        margin-bottom:25px;
        height:20.5rem;
        border:hidden;
        border-radius: 50px;
        font-size: small;
        
    }

    .myinvest br{
        display: none;

    }
    .mobile-card{
        
        display: flex;
        border: hidden;
        width: 100%;
        color: white;
        position: sticky;
    
    }
.my-container{
    background-color: whitesmoke;
}

.main-container{
    background-color: #110451;
    height: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    position: fixed;


}

.main-container h1{
    text-align: left;
    padding-right: 40px;
    margin-right: 40px;
    overflow: hidden;
    position: fixed;

}
.mycard-pending{
    display: block;
  
}

.mycard-pending a{
    color: white;
}

.mycard-pending a:hover{
    color: blue;

}

.table-head-font{
    font-size: small;
    background-color:#110451;
    color:white;
}


  
}

@media only screen and (max-width: 800px){

    .my-card{
        border-radius:30px;
        width:16.5rem;
        padding:10px;
        justify-items: center;
        margin: 10px;
        background-blend-mode: normal;
       font-size: small;
       display: block;
       height: 9rem;
       margin-right: 100px;
    

   
    }

    .my-icon{
        padding-left:85%;
        color:white
    }

    .navicons{
        display: none;
    }

    .mysidebar{
        display: none;
    }

    .mynavbar{
        background-color: #333;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .nocardi{
        display: none;
    }
    .nocardii{
        display: none;
    }

    .my-card-withdraw{
        display: none;
    }

    .myinvest{
        width: 20rem;
        margin-right:35px;
        margin-bottom:25px;
        height:20.5rem;
        border:hidden;
        border-radius: 50px;
        font-size: small;
        
    }

    .myinvest br{
        display: none;

    }
    .mobile-card{
        
        display: flex;
        border: hidden;
        width: 100%;
        color: white;
        position: sticky;
    
    }
.my-container{
    background-color: whitesmoke;
}

.main-container{
    background-color: #110451;
    height: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    position: fixed;


}

.main-container h1{
    text-align: left;
    padding-right: 40px;
    margin-right: 40px;
    overflow: hidden;
    position: fixed;

}
.mycard-pending{
    display: block;
  
}

.mycard-pending a{
    color: white;
}

.mycard-pending a:hover{
    color: blue;

}

.table-head-font{
    font-size: small;
    background-color:#110451;
    color:white;
}


  
}


@media only screen and (max-width: 1200px){

    .my-card{
        border:hidden;
        width: 13rem;
        height:9rem;
        justify-content: space-between;
        border-radius:10px;
        box-shadow: revert;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .mini-card{
        border:hidden;
        width:12.3rem;
        height:7.2rem;
        margin-left: 3px;
        margin: 2px;
        border-radius:10px;
        box-shadow: revert;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    
    }
    .mini-button{
        width: 100%;
        padding: 1px;
        margin-top: 1px;
    }

    .my-icon{
       display: none;
    }

    .navicons{
        display: none;
    }

    .mysidebar{
        display: block;
    }

    .mynavbar{
        background-color: #333;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .nocardi{
        width: 27rem;
        height:18rem;
        border:hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    }
    .nocardii{
        width: 25rem;
        height:18rem;
        border:hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    }

    .my-card-withdraw{
     
        border:hidden;
        width:12.5rem;
        height:9rem;
        border-radius:10px;
        box-shadow: revert;
        background:linear-gradient(90deg,#110451 30%, #0099ff 100%);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        justify-content: space-between
    
    
    }

    .myinvest{
        width: 20rem;
        margin-right:35px;
        margin-bottom:25px;
        height:20.5rem;
        border:hidden;
        border-radius: 50px;
        font-size: small;
        
    }

    .myinvest br{
        display: none;

    }
    .mobile-card{
        
      display: none;
    
    }
.my-container{
    background-color: whitesmoke;
}

.main-container{
    background-color: #110451;
    height: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    position: fixed;


}

.main-container h1{
    text-align: left;
    padding-right: 40px;
    margin-right: 40px;
    overflow: hidden;
    position: fixed;

}
.mycard-pending{
    display: none;
  
}

.mycard-pending a{
    color: white;
}

.mycard-pending a:hover{
    color: blue;

}

.table-head-font{
    font-size: small;
    background-color:#110451;
    color:white;
}


  
}

@media only screen and (max-width: 900px){

    .my-card{
        border-radius:30px;
        width:16.5rem;
        padding:10px;
        margin: 20px;
        background-blend-mode: normal;
       font-size: small;
       height: 9rem;
       margin-right: 100px;
    

   
    }

   

    .navicons{
        display: none;
    }

    .mysidebar{
        display: none;
    }

    .nocardi{
        display: none;
    }
    .nocardii{
        display: none;
    }

    .my-card-withdraw{
        border-radius:30px;
        width:16.5rem;
        padding:10px;
        margin: 20px;
        background-blend-mode: normal;
       font-size: small;
       height: 9rem;
       margin-right: 100px;
    }

    .myinvest{
        width: 20rem;
        margin-right:35px;
        margin-bottom:25px;
        height:20.5rem;
        border:hidden;
        border-radius: 50px;
        font-size: small;
        
    }

    .myinvest br{
        display: none;

    }
    .mobile-card{
        
        display: flex;
        border: hidden;
        width: 100%;
        color: white;
        position: sticky;
    
    }

.my-container{
    background-color: whitesmoke;
}

.main-container{
    background-color: #110451;
    height: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    position: fixed;


}

.main-container h1{
    text-align: left;
    padding-right: 40px;
    margin-right: 40px;
    overflow: hidden;
    position: fixed;

}
.mycard-pending{
    display: block;
  
}

.mycard-pending a{
    color: white;
}

.mycard-pending a:hover{
    color: blue;

}

.table-head-font{
    font-size: small;
    background-color:#110451;
    color:white;
}


  
}



