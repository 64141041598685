.fill-window {
    height: fit-content;
    position: absolute;
    left: 0;
    width: 100%;
    background-color:whitesmoke;
    
    
   
    
    
    
  }
  

  @media only screen and (max-width: 400px){
    .fill-window{
      height:100%;
      position:relative;
      width: 100%;
      background-color: whitesmoke;
    }
  }

  @media only screen and (max-width: 800px){
    .fill-window{
      height:100%;
      position:relative;
      width: 100%;
      background-color: whitesmoke;
    }
  }

  @media only screen and (max-width: 900px){
    .fill-window{
      height:100%;
      position:relative;
      width: 100%;
      background-color: whitesmoke;
    }
  }

  @media only screen and (max-width: 1200px){
    .fill-window{
      height:100%;
      position:relative;
      width: 100%;
      background-color: whitesmoke;
    }
  }