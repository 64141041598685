.mycard {
   margin-top: 100px;
   margin-left: 20%;
    width: 30%;
    text-align: center;
    height: 100%

}

.img{
    max-width: 25rem;
    height: 300px;
}