.logcard{
    width:30%;
    justify-self:center;
    font-weight:bold;
    background-color:whitesmoke;
    height: 100%;
    
    


}

@media only screen and (max-width: 800px){

    .logcard{
        width:80%;
        justify-self:center;
        font-weight:bold;
        background-color:whitesmoke;
        height: 10%;
        margin-right: 10px;
        
    
    
    }

   
}




