
.mysidebar{

    background-color: #110451;
    overflow: hidden;
    
   
    
}
.mysidebar a.active {
    color: white;
  }
  
  .mysidebar a:hover:not(.active) {
    color: white;
  }

  
  

.navbar{
    text-decoration: none;
    color: green;
}

.nav-bar-link{
    color: grey;
}

@media screen and (max-width: 700px) {
    .mysidebar {
      width: 100%;
      height: 100%;
      
    }
    .mysidebar a {float: left;}
    
  }
  
  @media screen and (max-width: 400px) {
    .mysidebar a {
      text-align: center;
      float: none;
    }
  }

.nav-bar-link:hover{
    color: azure;
    text-decoration: solid;
    

    

} 


  
.my-icon{
    color: gray;
    
}

.my-icon a.active {
    color: white;
  }
  
  .my-icon a:hover:not(.active) {
    color: white;
  }

  .logouticon{
    padding-right:15px;
    color: white;
    display: none;
}

.sidebarbutton{
    margin-right:40px;
    padding-right:40px;
    padding-top:80% ;
    margin-top: 80%;
   
}

  @media screen and (max-width: 700px) {
    .logouticon{
        display:flex
    }
  }
  

  @media screen and (max-width: 800px) {
    .mysidebar a {
      text-align: center;
      float: none;
    }
  }

.nav-bar-link:hover{
    color: azure;
    text-decoration: solid;
    

    

} 


  
.my-icon{
    color: gray;
    
}

.my-icon a.active {
    color: white;
  }
  
  .my-icon a:hover:not(.active) {
    color: white;
  }

  .logouticon{
    padding-right:15px;
    color: white;
    display: none;
}

.sidebarbutton{
    margin-right:40px;
    padding-right:40px;
    padding-top:80% ;
    margin-top: 80%;
   
}
