

.img{
    max-width: 40px;
    max-height: 10em;
    
}


.my-card{
    border-color: darkgrey;
    

}

.my-card:hover{
    border-color: blueviolet;
}

.my-table{

    background-color: #ff6600 !important
    
}
.my-button{
    color: white;
    background-color: transparent;
    border: none;
}

.my-button:hover{
    color:gray;
    background-color: transparent;
    border-color: #110451;
    text-shadow: #110451;
    
    
}

.invest-container{
    text-align:center; 
    padding-right:40px;
     font-weight:bold;
     color:#110451;
     font-size:13mm;
     font-family:verdana;
}

.invest-plans{
    font-family:monospace;
      top: 60px;
     width: 100%;
    text-align:center;
    height:23rem;
    border:hidden;
     border-radius:15px 50px

}
.invest-title{
    font-size:13mm;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}


@media only screen and (max-width: 400px){

    .invest-container {
        font-size: 5mm;
    }

    .invest-plans{
        height:28em;
        padding:5px;
        margin-right:20px;
        margin-bottom: 20px;
        
    }

    .invest-title{
        font-size: 9mm;
    }
}


@media only screen and (max-width: 500px){

    .invest-container {
        font-size: 5mm;
    }

    .invest-plans{
        height:28em;
        width: 18rem;
        padding:5px;
        margin-right:20px;
        margin-bottom: 20px;
        
    }

    .invest-title{
        font-size: 9mm;
    }
}



@media only screen and (max-width: 800px){

    .invest-container {
        font-size: 5mm;
    }

    .invest-plans{
        height:28em;
        width: 18rem;
        padding:5px;
        margin-right:20px;
        margin-bottom: 20px;
        
    }

    .invest-title{
        font-size: 9mm;
    }
}


@media only screen and (max-width: 1200px){

    .invest-container {
        font-size: 5mm;
    }

    .invest-plans{
        font-family:monospace;
        width: 20rem;
        text-align:center;
        margin: 10px;
        height:22rem;
        border:hidden;
        border-radius:15px 50px
    
    }

    .invest-title{
        font-size: 9mm;
    }
}